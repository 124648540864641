import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import PageWrapper from "../../components/PageWrapper";
import {MainTitle, Section} from "../../components/Core";
import BlogList from "../../sections/blog/BlogList";
import { graphql, useStaticQuery } from "gatsby";
import Seo from "./../../components/Seo";
import { getProperty } from "../../utils/helperFn";

const News = () => {

  const data = useStaticQuery(graphql`
        query allNewsArticlesQuery {
          allStrapiNewsArticles(sort: {order: DESC, fields: backdate}) {
            edges {
              node {
                id
                strapiId
                backdate
                published_at
                title
                content
                slug
                 image_featured {
                   childImageSharp {
                     fluid(maxWidth: 400) {
                       ...GatsbyImageSharpFluid
                     }
                   }
                }
              }
            }
          }
          strapiContactPage {
            enquiry_notice
          }
          strapiPageHeadings(slug: {eq: "news"}) {
                h1
            }
        }
    `)

  const contactText = getProperty(data, 'strapiContactPage');
  const headingsData = getProperty(data, 'strapiPageHeadings');

  return (
    <>
      <Seo page="news" />
      <PageWrapper footerDark>
        <Section className="pb-0">
          <div className="pt-5" />
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="8">
                <MainTitle variant="hero">{headingsData.h1}</MainTitle>
              </Col>
            </Row>
          </Container>
        </Section>
        <BlogList articles={data} enquiryHtml={contactText.enquiry_notice} page="news"/>
      </PageWrapper>
    </>
  );
};
export default News;
